<template>
  <div>
    <!-- @click.native="$router.push('/customer')" -->
    <v-chart ref="chart" :forceFit="true" :style="`width:${chartWidth}px;`" :height="height" :data="demo ? data : t_data" :scale="scale" :padding="[25, 120, 0, 0]"
      :on-click="clickHandler">
      <v-tooltip :showTitle="false" :item-tpl="tooltipOpts.itemTpl" />
      <v-axis />
      <v-legend dataKey="item" position="right-center"/>
      <v-pie
        position="percent"
        :color="color"
        :vStyle="pieStyle"
        :label="labelConfig"
        :tooltip="tooltipOpts.tooltip"
      />
      <v-coord type="theta" />
    </v-chart>
  </div>
</template>

<script>
import DataSet from '@antv/data-set'

const sourceData = [
  { item: '未联络', count: 40 },
  { item: '有意向', count: 21 },
  { item: '无意向', count: 17 },
  { item: '已成交', count: 10 }
];

const scale = [{
  dataKey: 'percent',
  min: 0,
  formatter: '.0%',
}];

const dv = new DataSet.View().source(sourceData);
dv.transform({
  type: 'percent',
  field: 'count',
  dimension: 'item',
  as: 'percent'
});
const data = dv.rows;

const tooltipOpts = {
  showTitle: false,
  itemTpl: '<li data-index={index} style="margin-bottom:4px;">'
      + '<span style="background-color:{color};" class="g2-tooltip-marker"></span>'
      + '{name}<br/>'
      + '<span style="padding-left: 16px">数量：{count}</span><br/>'
      + '<span style="padding-left: 16px">占比：{percent}</span><br/>'
      + '</li>',
  tooltip: ['item*count*percent', (item, count, percent) => ({
    name: item,
    percent: Math.round(percent * 100) + '%',
    count: count,
  })]
};

const color = ['item', ['#ef5350', '#607D8B', '#00BCD4', '#4CAF50', '#BAE7FF']];

export default {
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    demo: {
      type: Boolean,
      default: false
    },
    chartWidth: {
      type: Number,
      default: 350
    }
  },
  created() {
  },
  mounted() {
    this.mountedFlag = true
  },
  data() {
    return {
      mountedFlag: false,
      data,
      scale,
      color,
      height: 180,
      tooltipOpts,
      pieStyle: {
        stroke: "#fff",
        lineWidth: 1,
        color: ['#9E9E9E', '#607D8B', '#00BCD4', '#4CAF50', '#BAE7FF']
      },
      labelConfig: ['percent', {
        offset: -30,
        textStyle: {
          rotate: 0,
          textAlign: 'center',
          shadowBlur: 2,
          shadowColor: 'rgba(0, 0, 0, .45)'
        }
      }],
    };
  },
  methods: {
    doLayout() {
      setTimeout(() => {
        this.$refs.chart.chart.chartInstance.forceFit()
      }, 300)
    },
    getContent: (obj) => {
      return parseInt(String(Math.round(obj.percent * 100))) + '%';
    },
    clickHandler() {
      // console.log(e, chart)
      // console.log(e.data._origin)
      // const arr = ['', '未联络', '有意向', '无意向', '已成交']
      // this.$router.push(`/customer?typeid=${arr.indexOf(e.data._origin.item)}`)
    }
  },
  computed: {
    t_data() {
      return new DataSet.View().source(this.items).transform({
        type: 'percent',
        field: 'count',
        dimension: 'item',
        as: 'percent'
      }).rows;
    }
  },
  doLayout() {
    setTimeout(() => {
      this.$refs.chart.chart.chartInstance.forceFit()
    }, 300)
  }
};
</script>
